import { Injectable } from '@angular/core';
import {Constants} from '../../constant/Constants';
import {environment} from '../../../../environments/environment';

const ls = localStorage;

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  baseMainWebUrl = environment.baseMainWebUrl;

  constructor() {}

  public getTenantUnionId(): any {
    return ls.getItem(Constants.KEY_LS_TENANT_UNION_ID);
  }

  public setTenantUnionId(unionId: string): void {
    ls.setItem(Constants.KEY_LS_TENANT_UNION_ID, unionId);
  }

  public getUserName(): any {
    return ls.getItem(Constants.KEY_LS_USER_SYS_ID);
  }

  public setUserName(userName: string): void {
    ls.setItem(Constants.KEY_LS_USER_SYS_ID, userName);
  }

  public getUserId(): any {
    return ls.getItem(Constants.KEY_LS_USER_SYS_ID);
  }

  public setUserId(userId: string): void {
    ls.setItem(Constants.KEY_LS_USER_SYS_ID, userId);
  }


  // public setToken(token: string): void {
  //   ls.setItem(Constants.KEY_LS_TOKEN, token);
  // }
  public getTokenWithLoginRedirect(): any {

    const token = ls.getItem(Constants.KEY_LS_TOKEN);
    if (token === '' || token === undefined) {
      window.location.assign(Constants.URL_MOD_LOGIN);
    }
    return token;
  }

  public getToken(): any {

    const token = ls.getItem(Constants.KEY_LS_TOKEN);
    return token;

  }


  public setToken(token: string): void {
    ls.setItem(Constants.KEY_LS_TOKEN, token);
  }


  public clearUserInfo(): any {
    ls.removeItem(Constants.KEY_LS_USER_SYS_ID);
    ls.removeItem(Constants.KEY_LS_USER_NAME);
    ls.removeItem(Constants.KEY_LS_TOKEN);
  }

  public logoutNotificationForMain(): void {
    console.log('logoutNotificationForMain');
    const iframe2 = document.createElement('iframe');
    iframe2.src = this.baseMainWebUrl + '/mainlogoutrecevier';
    document.body.appendChild(iframe2);
    setTimeout(() => {
      if (iframe2.contentWindow !== null ) {
        iframe2.contentWindow.postMessage('', this.baseMainWebUrl + '/mainlogoutrecevier');
      }
    }, 500);
    setTimeout(() => {
      iframe2.remove();
    }, 1000);
  }

  public logoutNotificationForWorkbrench(): void {
    console.log('logoutNotificationForWorkbrench');
    const iframe3 = document.createElement('iframe');
    iframe3.src = this.baseMainWebUrl + ':81/workbrenchlogoutrecevier';
    document.body.appendChild(iframe3);
    setTimeout(() => {
      if (iframe3.contentWindow !== null ) {
          iframe3.contentWindow.postMessage('', this.baseMainWebUrl + ':81/workbrenchlogoutrecevier');
      }
    }, 2000);
    setTimeout(() => {
      iframe3.remove();
    }, 2500);
  }


  public loginNotificationForMain(token: string): void {
    console.log('loginNotificationForMain');
    const iframe2 = document.createElement('iframe');
    iframe2.src = this.baseMainWebUrl + '/mainloginrecevier';
    document.body.appendChild(iframe2);
    setTimeout(() =>  {
      if (iframe2.contentWindow !== null ) {
        iframe2.contentWindow.postMessage(token, this.baseMainWebUrl + '/mainloginrecevier');
      }
    }, 500);
    setTimeout(() =>  {
      iframe2.remove();
    }, 1000);
  }

  public loginNotificationForWorkbrench(token: string): void {
    console.log('loginNotificationForWorkbrench');
    const iframe3 = document.createElement('iframe');
    iframe3.src = this.baseMainWebUrl + ':81/workbrenchloginrecevier';
    document.body.appendChild(iframe3);
    setTimeout(() => {
      if (iframe3.contentWindow !== null ) {
        iframe3.contentWindow.postMessage(token, this.baseMainWebUrl + ':81/workbrenchloginrecevier');
      }
    }, 2000);
    setTimeout(() => {
      iframe3.remove();
    }, 2500);
  }

}
