import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../services/data/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    console.log('[login]contact ngOnInit addEventListener before');
    window.addEventListener('message', (event) => {
      console.log('[login]contact customd addEventListener receive data========');
      if (event.data.type !== 'webpackOk' && event.data.type !== 'webpackInvalid')
      {
        console.log('[login]contact save token');
        this.localStorageService.setToken(event.data);
      }

    });
    console.log('[login]contact ngOnInit addEventListener after');
  }

}
