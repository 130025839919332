// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseApiUrl: 'https://api.umuchicken.com/',
  baseImageCommonUrl: 'https://file.umuchicken.com/image/common/',
  baseImageUrl: 'https://file.umuchicken.com/image/',
  baseFileUrl: 'https://file.umuchicken.com/file/',
  imageUploadUrl: 'https://api.umuchicken.com/fud/image/api/upload',
  fileUploadUrl: 'https://api.umuchicken.com/fud/file/api/upload',


  // baseUrl: 'http://localhost/',
  // baseMainWebUrl: 'http://localhost/',
  // baseWorkWebUrl: 'http://localhost:81/',
  // baseContactWebUrl: 'http://localhost:82/',

  baseUrl: 'https://www.umuchicken.com/',
  baseMainWebUrl: 'https://www.umuchicken.com/',
  baseWorkWebUrl: 'https://nocode.umuchicken.com/',
  baseContactWebUrl: 'https://contacts.umuchicken.com/',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
