export class Constants {

  public static SUCCESS_CODE = '200';

  // public static BASE_URL_WEBSITE = 'http://110.41.5.250';
  //public static BASE_URL_WEBSITE = 'http://localhost';

  //public static URL_MOD_FORM = 'http://110.41.5.250:81/pages/dashboard';
  //public static URL_MOD_FORM = 'http://localhost:81/pages/dashboard';
  public static URL_MOD_FORM = 'pages/dashboard';

  //public static URL_MOD_LOGIN = 'http://110.41.5.250:83/login';
  // public static URL_MOD_LOGIN = 'http://localhost/login';
  public static URL_MOD_LOGIN = 'login';

  public static KEY_LS_TOKEN = 'USER-TOKEN';

  public static KEY_LS_USER_SYS_ID = 'USER-SYS-ID';

  public static KEY_LS_USER_NAME = 'USER-NAME';

  public static KEY_LS_TENANT_UNION_ID = 'TENANT_UNION_ID';

  public static DEFAULT_USER_HEAER_IMG = 'ic_hunter_logo_new.png';





}
